import * as React from "react"

import { Layout, _Section, HeadComponent } from "../components/shared"
import { PageWrapper } from "../utils/PageWrapper"
import { Hero } from "../components/productsPage/shared"
import { _h2 } from "../components/shared/headline.styles"
import { _Blockquote } from "../components/shared/blockquote.styles"
import { _linkButton } from "../components/shared/buttons"

const NotFoundPage: React.FC = () => {
    return (
        <>
            <HeadComponent title="404 | Radicle" />
            <PageWrapper>
                <Layout>
                    <_Section>
                        <Hero center={true} title="404" lead="The site you were looking for is not available anymore.">
                            <_linkButton to="/">Go to Home Page</_linkButton>
                        </Hero>
                    </_Section>
                </Layout>
            </PageWrapper>
        </>
    )
}

export default NotFoundPage
